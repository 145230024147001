import React from "react"; // Removed useState since it's unused
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import "./Header.css";

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="md" className="header-container">
      <Container>
        {/* Left side with logo */}
        <Navbar.Brand as={Link} to="/" className="header-logo">
          <img
            src="/images/FootballMemeLogo.png"
            alt="Football Meme Logo"
            className="logo-img"
          />
        </Navbar.Brand>

        {/* Center with FootballMeme text styled as the logo */}
        <Navbar.Brand as={Link} to="/" className="logo-text">
          FootballMeme
        </Navbar.Brand>

        {/* Hamburger toggler for mobile */}
        <Navbar.Toggle aria-controls="navbar-nav" />

        {/* Right side with navigation links */}
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto header-nav">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
