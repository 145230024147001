import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./About.css"; // Custom styling for the page

const About = () => {
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "", visible: false });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { email, suggestion };

    const apiBaseUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:5001"
        : "https://api.footballmeme.net";

    try {
      const response = await fetch(`${apiBaseUrl}/submitSuggestion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setAlert({
          message: "Thank you for your suggestion, it has been received!",
          type: "success",
          visible: true,
        });
        setEmail("");
        setSuggestion("");
        setShowForm(false);
      } else {
        setAlert({
          message:
            "There was a problem sending feedback. Please try again later.",
          type: "danger",
          visible: true,
        });
      }
    } catch (error) {
      console.error("Error submitting suggestion:", error);
      setAlert({
        message:
          "There was a problem sending feedback. Please try again later.",
        type: "danger",
        visible: true,
      });
    }
  };

  return (
    <div className="about-container">
      <Helmet>
        <title>About | Football Meme | The Fast Index on All Things NFL</title>
        <meta
          name="description"
          content="Learn more about the NFL news platform and its creator."
        />
      </Helmet>

      {/* Display Bootstrap Alert if it's visible */}
      {alert.visible && (
        <Alert
          variant={alert.type}
          onClose={() => setAlert({ message: "", type: "", visible: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <div className="about-page">
        <h1 className="about-header">About This Site</h1>
        <p>
          This site is a curated NFL news platform, providing the latest news,
          videos, and player transactions. We aggregate content from top NFL
          sources and YouTube channels to keep you updated in real-time.
        </p>
        <p>
          We value journalism and ensure that creators are credited by linking
          directly to their content instead of summarizing it with AI. This way,
          you can visit the source and support their work.
        </p>
        <p>
          AI is used only to categorize content (e.g., "transaction" or
          "injury") for easier browsing.
        </p>

        <h1 className="about-header">Why Football Meme?</h1>
        <p>
          I love the NFL and used to watch games religiously while also
          scrolling Twitter. After Elon killed Twitter with his hate, misogyny,
          homophobia, transphobia, and general buffoonery, I found I still
          needed a way to follow the league in a live, reverse-chronological
          fashion.
        </p>
        <p>
          Football Meme gives me a fast, simple index on all NFL-related news. I
          can then click-through to the original site to learn more.
        </p>
        <p>
          If there's anything else you'd like to see in the app, please{" "}
          <span
            className="let-me-know"
            onClick={() => setShowForm(!showForm)}
            style={{ cursor: "pointer" }}
          >
            let me know
          </span>
          .
        </p>

        {showForm && (
          <Form onSubmit={handleSubmit} className="suggestion-form">
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="suggestion" className="mt-3">
              <Form.Label>Suggestion:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                required
              />
            </Form.Group>

            <div className="mt-3">
              <Button variant="primary" type="submit">
                Send
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowForm(false);
                  setEmail("");
                  setSuggestion("");
                }}
                className="ms-2"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}

        <h1 className="about-header">About Prashant</h1>
        <p>
          I specialize in developer marketing. Learn more about my work on{" "}
          <a
            href="http://www.strategicnerds.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            my blog
          </a>
          . This site helps me test developer products and services.
        </p>
        <p>
          If you need help bringing a developer product to market, learn about my{" "}
          <a
            href="http://www.strategicnerds.com/fractional-cmo-services"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fractional CMO Services
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
