import React from "react";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import { timeDifference } from "../utils/timeUtils";
import "./Article.css";

const getPillVariant = (articleType) => {
  switch (articleType) {
    case "news":
      return "success";
    case "rumor":
      return "warning";
    case "transaction":
      return "danger";
    case "game":
      return "primary";
    case "injury":
      return "warning";
    default:
      return "secondary";
  }
};

const Article = ({ article }) => {
  const handleClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/registerclick`, {
        url: article.url, // Send the article URL in the request body
      });
      console.log("Click registered successfully");
    } catch (error) {
      console.error("Error registering click:", error);
    }
  };

  return (
    <div className="article-item d-flex flex-column my-3">
      {/* Article headline */}
      <div className="d-flex align-items-center">
        <a
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          className="article-headline"
          onClick={handleClick} // Register click when the link is clicked
        >
          {article.headline}
        </a>
        <Badge bg={getPillVariant(article.type)} className="ms-2">
          {article.type}
        </Badge>
      </div>

      {/* Time and domain name */}
      <small className="text-muted">
        {timeDifference(article.publication_date)} |{" "}
        <a
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          className="article-domain"
        >
          {article.site}
        </a>
      </small>
    </div>
  );
};

export default Article;
